<template>
  <div class="box">
    <div class="top">
      <div class="banner">
        <img :src="bannerImage" style="width: 60%" alt="">
      </div>
      <div class="yidong">
        <div class="top-input" style="margin: 30px 20px">
          <img :src="inputSearch" style="width: 20px;margin-left: 10px" alt="">
          <input placeholder="搜索单位/科室/岗位名称" readonly @click="searchClick" class="inputS" style="width: 87%">
        </div>

        <div class="top-tag">
          <div class="top-tag-div" :style="{color: selectTag === item ? '#0B8EEE' : 'black'}" @click="tagChange(item)" v-for="(item, index) in tagList" :key="index">
            {{item}}
            <div v-if="selectTag === item" class="selectTg"></div>
          </div>
        </div>

      </div>

    </div>
    <div v-if="selectTag !== '推荐'" style="margin-bottom: 7px;background-color: white;width: 100%" class="tagChangeBox">
      <div style="width: 100%;display: flex;align-items: center;justify-content: center;opacity: 0" id="disDiv">
        <div style="border-top: 1px solid #ecebeb;width: 88%;height: 1px;display: flex;"></div>
      </div>
      <div class="top_change" v-if="selectTag === '进修'">
        <ChangeTab key="change1" @change="topChange" :default-value="'科室'" :api-columns="'hospitalDepartment'" :noSelectValue="apiData.hospitalDepartment" :columns="columns.departments"></ChangeTab>
        <ChangeTab key="change2"  @change="topChange" :default-value="'年限'" :api-columns="'cycle'" :noSelectValue="apiData.cycle"  :columns="columns.cycle"></ChangeTab>
        <ChangeTab key="change3"  @change="topChange" :default-value="'学历'" :api-columns="'education'" :noSelectValue="apiData.education"  :columns="columns.education"></ChangeTab>
        <ChangeTab key="change4" @change="topChange" :default-value="'住宿'" :api-columns="'stay'" :noSelectValue="apiData.stay"  :columns="columns.stay"></ChangeTab>
      </div>
      <div class="top_change" v-else-if="selectTag === '招聘'">
        <ChangeTab key="change5"  @change="topChange" :default-value="'科室'" :api-columns="'hospitalDepartment'" :noSelectValue="apiData.hospitalDepartment"  :columns="columns.departments"></ChangeTab>
        <ChangeTab key="change6" @change="topChange" :default-value="'城市'" :api-columns="'city'" :noSelectValue="apiData.city"  :columns="columns.city"></ChangeTab>
        <ChangeTab key="change7"  @change="topChange" :default-value="'学历'" :api-columns="'education'" :noSelectValue="apiData.education"  :columns="columns.education"></ChangeTab>
        <ChangeTab key="change8" @change="topChange" :default-value="'薪资'" :api-columns="'salary'" :noSelectValue="apiData.salary"  :columns="columns.salary"></ChangeTab>
        <ChangeTab key="change9"  @change="topChange" :default-value="'经验'" :api-columns="'experience'" :noSelectValue="apiData.experience"  :columns="columns.experience"></ChangeTab>
      </div>
      <div class="top_change" v-else-if="selectTag === '培训'">
        <ChangeTab key="change10"  @change="topChange" :default-value="'科室'" :api-columns="'hospitalDepartment'" :noSelectValue="apiData.hospitalDepartment"  :columns="columns.departments"></ChangeTab>
        <ChangeTab key="change11" @change="topChange" :default-value="'费用'" :api-columns="'trainingExpenses'" :noSelectValue="apiData.trainingExpenses"  :columns="columns.trainingExpenses"></ChangeTab>
        <ChangeTab key="change12"  @change="topChange" :default-value="'住宿'" :api-columns="'stay'" :noSelectValue="apiData.stay"  :columns="columns.stay"></ChangeTab>
        <ChangeTab key="change13" @change="topChange" :default-value="'时长'" :api-columns="'cycle'" :noSelectValue="apiData.cycle"  :columns="columns.cycle2"></ChangeTab>
      </div>
      <div class="top_change" v-else-if="selectTag === '收藏'">
        <div @click="shouCangChange('jinxiu')" :style="{backgroundColor: !selectValue.jinxiu ? '#F2F2F2' : '#DBEFFF',color: !selectValue.jinxiu ? 'black' : '#0991FA'}" class="box2">进修</div>
        <div @click="shouCangChange('zhaopin')" :style="{backgroundColor: !selectValue.zhaopin ? '#F2F2F2' : '#DBEFFF',color: !selectValue.zhaopin ? 'black' : '#0991FA'}" class="box2">招聘</div>
        <div @click="shouCangChange('peixun')" :style="{backgroundColor: !selectValue.peixun ? '#F2F2F2' : '#DBEFFF',color: !selectValue.peixun ? 'black' : '#0991FA'}" class="box2">培训</div>
      </div>
    </div>
    <div v-if="selectTag === '订阅'" style="">
      <div v-if="!dingYueData || Object.keys(dingYueData).length === 0" style="padding-top: 8vh;height: 80px;color: #D1D1D1;font-size: 18px;display: flex;justify-content: center;align-items: center;flex-direction: column;">
        <div>
          您还没有订阅内容
        </div>
        <div class="dingYueBtn" @click="dingYueClick">
          点击订阅
        </div>
      </div>
      <div id="dingYue" @click="dingYueClick" style="display: flex;background-color: white;padding: 10px 20px;" v-else>
        <img :src="Subscription" style="width: 23px;height: 30px;padding-right: 5px;margin-left: 5px;margin-right: 5px">
        <div style="display: flex;flex-direction: column;">
          <div style="font-size: 18px">
            当前订阅: {{dingYue.jinxiu ? '进修' : ''}}{{dingYue.zhaopin ? '、招聘' : ''}}{{dingYue.peixun ? '、培训' : ''}}
          </div>
          <div style="font-size: 13px;color: #6b6b6b;margin-top: 10px">
            {{dingYue.selectColumns.department.join('、')}}&nbsp;|&nbsp;{{dingYue.selectColumns.city.join('、')}}&nbsp;|&nbsp;{{dingYue.selectColumns.unitType.join('、')}}
          </div>
        </div>
        <div style="display: flex;align-items: center;color: #999999">
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
<!--    <div style="overflow-y: scroll">-->
      <van-list
          v-if="dingYueData"
          style="background-color: #F9F9F9;"
          v-model="loading"
          :finished="finished"
          :finished-text="selectTag === '订阅' && finished === true && tableData.length === 0 ? '暂无相关订阅内容' : '没有更多了'"
          @load="onLoad"
      >
        <div class="list_tag" v-for="(item, index) in tableData" :key="index +  '&'" @click="clickRouter(item)">
          <template v-if="item.type === 'education'">
            <span class="list_tag_title_JX">进修</span>
            <span>
              <span v-if="item.createdByType === 'department'">[{{item.educationHospitalDepartment[0].name}}]</span>
              {{item.title}}</span>
            <span class="list_tag_title_JI" v-if="item.isImpatient === 1" style="background-color: #FF5075;">急</span>
            <span class="list_tag_title_JI" v-if="item.isNew === 1" style="background-color: #E0D852;">新</span>
            <div style="display: flex;margin-top: 7px" v-if="item.createdByType !== 'department'">
              <div style="color: #999999;font-size: 10px;" v-for="(educationHospital, index) in item.educationHospitalDepartment" :key="educationHospital.id">
                <span v-if="index !== 0">&nbsp;|&nbsp; </span> {{educationHospital.name}}
              </div>
            </div>
            <div style="display: flex;flex-wrap: wrap;">
              <div class="list_tag_tag1" v-if="item.cycle">
                {{item.cycle}}
              </div>
              <div class="list_tag_tag1" v-if="item.education">
                {{item.education}}
              </div>
              <div class="list_tag_tag1" v-if="item.stay">
                {{item.stay}}
              </div>
              <div class="list_tag_tag1" v-for="(tag, index) in item.tags" :key="index + '*'">
                {{tag}}
              </div>
            </div>
            <div class="list_tag_time">
              报名时间：{{moment(item.startTime).format('YYYY年MM月DD日')}} ~ {{moment(item.startTime).format('YYYY') === moment(item.endTime).format('YYYY') ? moment(item.endTime).format('MM月DD日') : moment(item.endTime).format('YYYY年MM月DD日')}} &nbsp;<span :style="{color: computedTime(item) === '未开始'? '#0091FC' : '#FD5F39'}">{{computedTime(item)}}</span>
            </div>
          </template>

          <template v-else-if="item.type === 'recruit'">
<!--            <div>-->
              <span class="list_tag_title_ZP">招聘</span>
              <span>
              [{{item.educationHospitalDepartment[0].name}}]
              {{item.title}}</span>
              <span class="list_tag_title_JI" v-if="item.isImpatient === 1" style="background-color: #FF5075;">急</span>
              <span class="list_tag_title_JI" v-if="item.isNew === 1" style="background-color: #E0D852;">新</span>
<!--            </div>-->

            <div style="font-size: 14px;color: red;margin-top: 5px">
              {{item.salary}}
            </div>
            <div style="display: flex;flex-wrap: wrap;">
              <div class="list_tag_tag1" v-if="item.experience">
                {{item.experience}}
              </div>
              <div class="list_tag_tag1" v-if="item.education">
                {{item.education}}
              </div>
              <div class="list_tag_tag1" v-if="item.city">
                {{item.city}}
              </div>
              <div class="list_tag_tag1" v-for="(tag, index) in item.tags" :key="index + '*'">
                {{tag}}
              </div>
            </div>
            <div style="margin-top: 10px;display: flex;align-items: center;font-size: 11px;color: #999999;    justify-content: space-between;">
              <span style="display: flex;    align-items: center;"><img :src="item.hospital.logo" style="width: 30px;height: 30px;">
              <span style="margin-left: 5px">{{item.hospital.name}}</span></span>
              <span style="float: right">{{moment(item.publishTime).format('YYYY年MM月DD日')}}发布</span>
            </div>
          </template>

          <template v-else-if="item.type === 'train'">
            <span class="list_tag_title_PX">培训</span>
            <span>
              {{item.title}}</span>
            <span class="list_tag_title_JI" v-if="item.isImpatient === 1" style="background-color: #FF5075;">急</span>
            <span class="list_tag_title_JI" v-if="item.isNew === 1" style="background-color: #E0D852;">新</span>
<!--            <div style="font-size: 14px;color: red;margin-top: 5px">-->
<!--              {{item.salary}}-->
<!--            </div>-->
            <div style="display: flex;flex-wrap: wrap;">
              <div class="list_tag_tag1" v-if="item.cycle">
                {{item.cycle}}
              </div>
              <div class="list_tag_tag1" v-if="item.trainingExpenses">
                {{item.trainingExpenses}}
              </div>
              <div class="list_tag_tag1" v-if="item.stay">
                {{item.stay}}
              </div>
              <div class="list_tag_tag1" v-for="(tag, index) in item.tags" :key="index + '*'">
                {{tag}}
              </div>
            </div>
            <div style="margin-top: 10px;display: flex;    flex-direction: column;font-size: 11px;color: #999999;">
              <div>
                {{item.abstract2}}
              </div>
              <div>
                报名时间：{{moment(item.startTime).format('YYYY年MM月DD日')}} ~ {{moment(item.startTime).format('YYYY') === moment(item.endTime).format('YYYY') ? moment(item.endTime).format('MM月DD日') : moment(item.endTime).format('YYYY年MM月DD日')}} &nbsp;<span :style="{color: computedTime(item) === '未开始'? '#0091FC' : '#FD5F39'}">{{computedTime(item)}}</span>
              </div>
            </div>
          </template>


<!--          <div class="">-->
<!--            {{item.startChinese}}-->
<!--          </div>-->
        </div>
      </van-list>
    <van-overlay :show="show">
      <div style="width: 100%;height: 100%;display: flex;    justify-content: center;
    align-items: center;" @click.stop="dingYueClose">
        <div class="dingYueVan_body" @click.stop>
          <div class="dingYueVan_body_title">
            <div>意向岗位订阅</div>
            <div style="font-size: 11px">最新消息实时通知，进修求职快人一步</div>
          </div>
          <div class="dingYueVan_body_content">
            <div class="dingYueVan_body_content_div" style="display: flex;    align-items: center;
    justify-content: space-between;">
              <span style="margin-top: -2px">分类</span>
              <span style="display: flex">
                <van-checkbox v-model="dingYue.jinxiu" shape="square">进修</van-checkbox>
                <van-checkbox style="margin: 0 5px" v-model="dingYue.zhaopin" shape="square">招聘</van-checkbox>
                <van-checkbox v-model="dingYue.peixun" shape="square">培训</van-checkbox>
              </span>
            </div>

            <div class="dingYueVan_body_content_div">
              <div>科室</div>
              <div class="dingYueVan_body_content_div_change" @click="keShiShowClick">
                <span v-if="dingYue.selectColumns.department.length > 0" style="width: 90%">{{dingYue.selectColumns.department.join(';')}}</span>
                <span v-else style="color: #999999;">请选择订阅科室（可多选）</span>
                <van-icon color="#999999" name="arrow" />
              </div>
            </div>
            <div class="dingYueVan_body_content_div">
              <div>城市</div>
              <div class="dingYueVan_body_content_div_change" @click="chengShiShowClick">
                <span v-if="dingYue.selectColumns.city.length > 0" style="width: 90%">{{dingYue.selectColumns.city.join(';')}}</span>
                <span v-else style="color: #999999;">请选择订阅城市（可多选）</span>
                <van-icon color="#999999" name="arrow" />
              </div>
            </div>
            <div class="dingYueVan_body_content_div">
              <div>单位性质</div>
              <div class="dingYueVan_body_content_div_change" @click="danWeiClick">
                <span v-if="dingYue.selectColumns.unitType.length > 0" style="width: 90%">{{dingYue.selectColumns.unitType.join(';')}}</span>
                <span v-else style="color: #999999;">请选择订阅单位类型（可多选）</span>
                <van-icon color="#999999" name="arrow" />
              </div>
            </div>

            <div class="dingYueBtn" @click="dingYueSubmit" style="font-size: 16px;margin: 20px auto">
              点击订阅
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
<!--    </div>-->
    <van-popup @closed="keShiClose" v-model="keShiShow" position="bottom" :style="{ height: '60%' }">
      <div class="keShiPopup_button">
        <div style="color: #999999" @click="keShiCloseA">取消</div>
        <div style="color: #1BB480" @click="keShiAdd">确认</div>
      </div>
      <van-checkbox-group style="    height: 85%;
    overflow: scroll;" v-model="dingYue.cacheColumns.department">
        <van-cell-group>
          <van-cell
              v-for="(item, index) in columns.departments.filter(row => row !== '全部')"
              clickable
              :key="item"
              :title="`${item}`"
              @click="toggle(index)"
          >
            <template #right-icon>
              <van-checkbox :name="item" ref="ksCheckboxes" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-checkbox-group>
<!--      <div class="keShiPopup_div" v-for="(item, index) in columns.departments.filter(item => item !== '全部')" :key="index + ')'">-->
<!--        <div>-->
<!--          {{item}}-->
<!--        </div>-->
<!--        <div>-->
<!--          <van-checkbox v-model="checked">复选框</van-checkbox>-->
<!--        </div>-->
<!--      </div>-->
    </van-popup>

    <van-popup @closed="chengShiClose" v-model="chengShiShow" position="bottom" :style="{ height: '60%' }">
      <div class="keShiPopup_button">
        <div style="color: #999999" @click="chengShiCloseA">取消</div>
        <div style="color: #1BB480" @click="chengShiAdd">确认</div>
      </div>
      <van-checkbox-group style="    height: 85%;
    overflow: scroll;" v-model="dingYue.cacheColumns.city">
        <van-cell-group>
          <van-cell
              v-for="(item, index) in columns.city.filter(row => row !== '全部')"
              clickable
              :key="item"
              :title="`${item}`"
              @click="csToggle(index)"
          >
            <template #right-icon>
              <van-checkbox :name="item" ref="csCheckboxes" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-checkbox-group>
    </van-popup>

    <van-popup @closed="danWeiClose" v-model="danWeiShow" position="bottom" :style="{ height: '60%' }">
      <div class="keShiPopup_button">
        <div style="color: #999999" @click="danWeiCloseA">取消</div>
        <div style="color: #1BB480" @click="danWeiAdd">确认</div>
      </div>
      <van-checkbox-group style="    height: 85%;
    overflow: scroll;" v-model="dingYue.cacheColumns.unitType">
        <van-cell-group>
          <van-cell
              v-for="(item, index) in columns.unitType.filter(row => row !== '全部')"
              clickable
              :key="item"
              :title="`${item}`"
              @click="dwToggle(index)"
          >
            <template #right-icon>
              <van-checkbox :name="item" ref="dwCheckboxes" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-checkbox-group>
    </van-popup>
  </div>
</template>

<script>
import bannerImage from '@/assets/image/Recruitment/eRBanner.png'
import inputSearch from '@/assets/image/Recruitment/search.png'
import Subscription from '@/assets/image/Recruitment/subscription.png'
import {
 
  getRecommendApi,
  getRecruitmentChengShiApi,
  getRecruitmentDingYueApi,
  getRecruitmentDingYueListApi,
  getRecruitmentShouCangApi,
  getRecruitmentTableData,
  getShuJuZiDianApi,
  postRecruitmentDingYueAddApi, postRecruitmentMaiDianApi
} from "@/service/recruitmentApi";
import {getAdmins} from "@/utils/adminDate";
import {setById} from "@/service/base";
import moment from "moment";
import ChangeTab from "@/components/Recruitment/ChangeTab";
import {Toast} from "vant";
export default {
  name: "Index",
  data () {
    return {
      danWeiShow: false,
      keShiShow: false,
      show: false,
      Subscription,
      moment,
      loading: false,
      bannerImage,
      inputSearch,
      finished: false,
      tagList: ['推荐', '进修', '招聘', '培训', '订阅', '收藏'],
      selectTag: '推荐',
      curPage: 0,
      pageSize: 10,
      tableData: [],
      columns: {
        departments: [],
        cycle: [],
        stay: [],
        experience: [],
        salary: [],
        city: [],
        trainingExpenses: [],
        cycle2: [],
        unitType: [],
      },
      apiData: {
        hospitalDepartment: '',
        cycle: '',
        education: '',
        stay: '',
        city: '',
        salary: '',
        experience: '',
        trainingExpenses: '',
      },
      chengShiShow: false,
      dingYueData: {},
      dingYueList: [],
      dingYue: {
        jinxiu: false,
        zhaopin: false,
        peixun: false,
        selectColumns: {
          department: [],
          city: [],
          unitType: [],
        },
        cacheColumns: {
          department: [],
          city: [],
          unitType: [],
        }
      },
      cacheDingYue: {
        jinxiu: false,
        zhaopin: false,
        peixun: false,
        selectColumns: {
          department: [],
          city: [],
          unitType: [],
        },
        cacheColumns: {
          department: [],
          city: [],
          unitType: [],
        }
      },
      selectValue: {
        jinxiu: false,
        zhaopin: false,
        peixun: false,
      }
    }//dataReturn
  },
  watch: {
    '$route.path' : {
      immediate: false,
      handler(to, form) {
        if (to && to !== '/recruitmentIndex' && form && form === '/recruitmentIndex') {
          window.removeEventListener('scroll', this.handleScroll);
        } else if (form && form !== '/recruitmentIndex' && to && to === '/recruitmentIndex') {
          if (this.selectTag === '收藏' && form === '/recruitmentDetails') {
            this.finished = false
            this.loading = true
            this.tableData = []
            this.getShouCangFunc()
            const topInput = document.querySelector('.yidong');
            topInput.style.paddingBottom = '';
            topInput.style.backgroundColor = '';
            topInput.style.position = ''; // 或者具体到原本的relative/absolute等
            topInput.style.top = '';
            const tagEvent = document.getElementsByClassName('tagChangeBox')[0]
            tagEvent.style.position = ''; // 或者具体到原本的relative/absolute等
            tagEvent.style.top = '';
          }
          window.addEventListener('scroll', this.handleScroll);
        }
      }
    }
  },
  components: {
    ChangeTab
  },
  async created() {
    await this.test();
    await this.getColumns()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    async shouCangChange (type) {
      if (this.loading) {
        return
      }
      if (this.selectValue[type]) this.selectValue[type] = false
      else this.selectValue[type] = true
      this.finished = false
      this.loading = true
      this.tableData = []
      await this.getShouCangFunc()
    },
    async dingYueSubmit () {
      if (!this.dingYue.jinxiu && !this.dingYue.zhaopin && !this.dingYue.peixun) {
        Toast.fail('请选择分类！')
        return
      }
      if (this.dingYue.selectColumns.department.length === 0) {
        Toast.fail('请选择科室！')
        return
      }
      if (this.dingYue.selectColumns.city.length === 0) {
        Toast.fail('请选择城市！')
        return
      }
      if (this.dingYue.selectColumns.unitType.length === 0) {
        Toast.fail('请选择单位类型！')
        return
      }
      this.tableData = []
      this.curPage = 0
      this.finished = false
      this.loading = true
      const data = {
        type: [],
        department: this.dingYue.selectColumns.department,
        city: this.dingYue.selectColumns.city,
        unitType: this.dingYue.selectColumns.unitType,
      }
      if (this.dingYue.jinxiu) data.type.push('education')
      if (this.dingYue.zhaopin) data.type.push('recruit')
      if (this.dingYue.peixun) data.type.push('train')
      const res = await postRecruitmentDingYueAddApi(data)
      if (res.code === 0) {
        Toast.success('订阅成功')
        this.show = false
        await this.getDingYueFunc()
        await this.onLoad()
      }
    },
    clickRouter (item) {
      this.addMaiDian(item.id)
      const data = {
        channel: this.$router.currentRoute.query.channel,
        memberId: this.$router.currentRoute.query.memberId,
        id: item.id
      }
      this.$router.push({path: '/recruitmentDetails', query: data})
    },
    topChange (value, columns) {
      this.apiData[columns] = value
      this.tableData = []
      this.curPage = 0
      this.finished = false
      this.onLoad()
    },
    async getColumns () {
      const res = await getShuJuZiDianApi('YJKS')
      if (res.code === 0) {
    
         this.columns.departments = res.data.value.reverse()
          this.columns.departments.unshift('全部')
        // console.log("科室",this.columns.departments);
      }
      const cycle = await getShuJuZiDianApi('NX')
      if (cycle.code === 0) {
        this.columns.cycle = cycle.data.value
      }
      const education = await getShuJuZiDianApi('XL')
      if (education.code === 0) {
        this.columns.education = education.data.value
      }
      const stay = await getShuJuZiDianApi('ZS')
      if (stay.code === 0) {
        this.columns.stay = stay.data.value
      }
      const city = await getRecruitmentChengShiApi({})
      if (city.code === 0) {
        this.columns.city = city.data
      }
      const salary = await getShuJuZiDianApi('XZ')
      if (salary.code === 0) {
        this.columns.salary = salary.data.value
      }
      const experience = await getShuJuZiDianApi('JY')
      if (experience.code === 0) {
        this.columns.experience = experience.data.value
      }
      const trainingExpenses = await getShuJuZiDianApi('FY')
      if (trainingExpenses.code === 0) {
        this.columns.trainingExpenses = trainingExpenses.data.value
      }
      const cycle2 = await getShuJuZiDianApi('SC')
      if (cycle2.code === 0) {
        this.columns.cycle2 = cycle2.data.value
      }
      const unitType = await getShuJuZiDianApi('DWXZ')
      if (cycle2.code === 0) {
        this.columns.unitType = unitType.data.value
      }
    },
    computedTime (item) {
      // 获取 item.endTime 对应的 Moment 对象
      const endTimeMoment = moment(item.endTime);

      // 获取当前日期的 Moment 对象，包括时间部分设置为0（确保计算包含今天）
      const todayStartMoment = moment().startOf('day');

      // 计算 endTime 和今天开始的时间点之间的差异，单位为天，+1 表示包含今天
      const day = endTimeMoment.diff(todayStartMoment, "days") + 1;
      const today = moment(); // 获取当前日期的 Moment 对象
      const isStart = moment(item.startTime).isAfter(today); // 比较 someDate 是否晚于今天
      if (isStart) {
        return '未开始'
      } else if (day > 10) {
          return '报名中'
        } else if (day <= 10) {
          return '剩余' + day + '天截止报名'
        }
    },
    async test() {
      //像接口头部传用户信息
      let arrays = await getAdmins(
        this.$route.query.memberId,
        this.$route.query.channel
      );
      await setById(arrays);
    },
    async addMaiDian (contentId) {
      const data = {
        location: '',
        contentId,
        type: 'EDUCATION'
      }
      if (this.selectTag === '推荐') {
        data.location = 'EDUCATION_RECOMMEND'
      } else if (this.selectTag === '进修') {
        data.location = 'EDUCATION_EDUCATION'
      } else if (this.selectTag === '招聘') {
        data.location = 'EDUCATION_RECRUIT'
      } else if (this.selectTag === '培训') {
        data.location = 'EDUCATION_TRAIN'
      } else if (this.selectTag === '订阅') {
        data.location = 'EDUCATION_SUBSCRIPTION'
      } else if (this.selectTag === '收藏') {
        data.location = 'EDUCATION_ATTENTION'
      }
      await postRecruitmentMaiDianApi(data)
    },
    async onLoad () {
      this.curPage += 1
      if (this.selectTag === '订阅') await this.getDingYueDataFunc()
      else if (this.selectTag === '收藏') await this.getShouCangFunc()
      else await this.getTableData()
    },
    async getShouCangFunc () {
      const data = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        education: Number(this.selectValue.jinxiu),
        recruit: Number(this.selectValue.zhaopin),
        train: Number(this.selectValue.peixun)
      }
      const res = await getRecruitmentShouCangApi(data)
      if (res.code === 0) {
        this.tableData = [...this.tableData, ...res.data]
        if (res.count <= this.tableData.length) {
          this.finished = true
        }
      }
      this.loading = false
    },
    async getTableData () {
      this.loading = true
      if (this.selectTag === '推荐') {
        const data = {
          curPage: this.curPage,
          pageSize: this.pageSize
        }
        const res = await getRecommendApi(data)
        if (res.code === 0) {
          this.tableData = [...this.tableData, ...res.data]
          if (res.count <= this.tableData.length) {
            this.finished = true
          }
        }
        this.loading = false
      } else if (this.selectTag === '进修' || this.selectTag === '招聘' || this.selectTag === '培训') {
        const data = {
          type: this.selectTag === '进修' ? 'education' : this.selectTag === '招聘' ? 'recruit' : "train",
          curPage: this.curPage,
          pageSize: this.pageSize,
          ...this.apiData
        }
        const res = await getRecruitmentTableData(data)
        if (res.code === 0) {
          this.tableData = [...this.tableData, ...res.data]
          if (res.count <= this.tableData.length) {
            this.finished = true
          }
        }
        this.loading = false
      }
    },
    dingYueClose () {
      this.show = false
      setTimeout(() => {
        if (this.dingYueData) this.dingYue = JSON.parse(JSON.stringify(this.cacheDingYue))
      },100)
    },
    async getDingYueDataFunc () {
      this.loading = true
      const data = {
        curPage: this.curPage,
        pageSize: this.pageSize
      }
      const res = await getRecruitmentDingYueListApi(data)
      if (res.code === 0) {
        this.tableData = [...this.tableData, ...res.data]
        if (res.count <= this.tableData.length) {
          this.finished = true
        }
      }
      this.loading = false
    },
    keShiAdd () {
      this.dingYue.selectColumns.department = JSON.parse(JSON.stringify(this.dingYue.cacheColumns.department))
      this.keShiCloseA()
    },

    danWeiClick () {
      this.dingYue.cacheColumns.unitType = JSON.parse(JSON.stringify(this.dingYue.selectColumns.unitType))
      this.danWeiShow = true
    },
    danWeiAdd () {
      this.dingYue.selectColumns.unitType = JSON.parse(JSON.stringify(this.dingYue.cacheColumns.unitType))
      this.danWeiCloseA()
    },
    danWeiClose () {
      this.danWeiShow = false
    },
    danWeiCloseA () {
      this.danWeiShow = false
    },
    dwToggle(index) {
      this.$refs.dwCheckboxes[index].toggle();
    },

    chengShiShowClick () {
      this.dingYue.cacheColumns.city = JSON.parse(JSON.stringify(this.dingYue.selectColumns.city))
      this.chengShiShow = true
    },
    chengShiAdd () {
      this.dingYue.selectColumns.city = JSON.parse(JSON.stringify(this.dingYue.cacheColumns.city))
      this.chengShiCloseA()
    },
    chengShiClose () {
      this.chengShiShow = false
    },
    chengShiCloseA () {
      this.chengShiShow = false
    },
    csToggle(index) {
      this.$refs.csCheckboxes[index].toggle();
    },
    dingYueClick () {
      if (this.dingYueData) this.dingYue = JSON.parse(JSON.stringify(this.cacheDingYue))
      this.show = true
    },
    keShiShowClick () {
      this.dingYue.cacheColumns.department = JSON.parse(JSON.stringify(this.dingYue.selectColumns.department))
      this.keShiShow = true
    },
    keShiClose () {
      this.keShiShow = false
    },

    keShiCloseA () {
      this.keShiShow = false
    },

    async getDingYueFunc () {
      const res = await getRecruitmentDingYueApi(this.$router.currentRoute.query.memberId)
      if (res.code === 0) {
        this.dingYueData = res.data
        if (res.data) {
          if (res.data.type.includes('education')) this.dingYue.jinxiu = true
          if (res.data.type.includes('recruit')) this.dingYue.zhaopin = true
          if (res.data.type.includes('train')) this.dingYue.peixun = true
          this.dingYue.selectColumns.department = res.data.department
          this.dingYue.selectColumns.city = res.data.city
          this.dingYue.selectColumns.unitType = res.data.unitType
          this.cacheDingYue = JSON.parse(JSON.stringify(this.dingYue))
        }
      }
      this.loading = false
    },
    tagChange (name) {
      if (this.loading) {
        return
      }
      this.selectValue = {
        jinxiu: false,
        zhaopin: false,
        peixun: false,
      }
      this.dingYueData = {}
      this.tableData = []
      this.curPage = 0
      this.finished = false
      this.apiData = {
        hospitalDepartment: '',
        cycle: '',
        education: '',
        stay: '',
        city: '',
        salary: '',
        experience: '',
        trainingExpenses: '',
      }
      this.selectTag = name
      this.loading = true
      if (name === '订阅') {
        this.getDingYueFunc()
        return;
      }
      this.onLoad()
    },
    toggle(index) {
      this.$refs.ksCheckboxes[index].toggle();
    },
    searchClick() {
      const data = {
        channel: this.$router.currentRoute.query.channel,
        memberId: this.$router.currentRoute.query.memberId
      }
      this.$router.push({path: '/recruitmentSearch', query: data})
    },
    handleScroll() {
      const topInput = document.querySelector('.yidong');
      const topInput2 = document.querySelector('.top-input');
      const topInput3 = document.querySelector('.inputS');
      const disDiv = document.getElementById('disDiv')
      const tagEvent = document.getElementsByClassName('tagChangeBox')[0]
      const dingYueEvt = document.getElementById('dingYue')
      // const topTag = document.querySelector('.top-tag');
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      // 设定一个阈值，当滚动距离超过这个阈值时，手动设置输入框为固定定位
      const banner = document.getElementsByClassName('banner')[0]
      const stickyThreshold = banner.offsetHeight + 30; // 这个值应根据你的具体需求调整，例如等于.top的高度

      if (scrollTop > stickyThreshold) {

        topInput.style.backgroundColor = 'white';
        topInput.style.position = 'fixed';
        topInput.style.top = 0;
        topInput2.style.margin = '10px 20px';
        topInput2.style.backgroundColor = '#F5F4F4';
        topInput3.style.backgroundColor = '#F5F4F4';

       if (tagEvent) {
         const ydHeight = topInput.offsetHeight
         tagEvent.style.position = 'fixed';
         tagEvent.style.top = ydHeight + 'px';
       }

       if (dingYueEvt && this.selectTag === '订阅') {
         dingYueEvt.style.position = 'fixed';
         dingYueEvt.style.top = topInput.offsetHeight + 'px';
       }

       if (disDiv) disDiv.style.opacity = '1'
      } else {
        // 当滚动回顶部足够距离时，恢复原来的状态
        topInput3.style.backgroundColor = ''
        topInput2.style.backgroundColor = 'white';
        topInput2.style.margin = "30px 20px";
        topInput.style.paddingBottom = '';
        topInput.style.backgroundColor = '';
        topInput.style.position = ''; // 或者具体到原本的relative/absolute等
        topInput.style.top = '';
        if (tagEvent) {
          tagEvent.style.position = ''; // 或者具体到原本的relative/absolute等
          tagEvent.style.top = '';
        }

        if (dingYueEvt && this.selectTag === '订阅') {
          dingYueEvt.style.position = '';
          dingYueEvt.style.top = '';
        }

        if (disDiv) disDiv.style.opacity = '0'

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dingYueVan_body {
  width: 300px;
  min-height: 430px;
  background-color: white;
  border-radius: 15px;
  overflow: hidden;

  .dingYueVan_body_title {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10px;
    color: white;
    height: 50px;
    background: linear-gradient(to left, #00BDFF, #4760FF);
  }
  .dingYueVan_body_content {
    padding: 0 20px;

    .dingYueVan_body_content_div {
      padding: 15px 0;
      border-bottom: 2px solid #ecebeb;
    }

    .dingYueVan_body_content_div_change {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      margin-top: 10px;
    }
  }
}
.keShiPopup_div {
  padding: 15px 0;
  margin: 0 20px;
  border-bottom: 1px solid #ecebeb;
}
.keShiPopup_button {
  //position: relative;
  //top: 0;
  background-color: white;
  //position: sticky;
  //top: -1px;
  display: flex;
  font-family: SimHei;
  font-size: 17px;
  justify-content: space-between;
  padding: 15px 20px;
  //border-bottom: 1px solid #ecebeb;
}
.dingYueBtn {
  font-size: 18px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 40px;
  border-radius: 15px;
  background: linear-gradient(to bottom,  #4760FF,#00BDFF);
  color: white;
}
.box {
  min-height: 100vh;
  background-color: #F9F9F9;
  .top {
    min-height: 250px;
    background-image: linear-gradient(to bottom, #0191FB, white 96%);

    .top-input {
      background-color: white;
      //margin: 30px 20px;
      border-radius: 18px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      //position: sticky;
      //top: 0;
      //z-index: 1000;
    }

    .top-tag {
      width: 100%;
      display: flex;
      justify-content: space-around;
      font-size: 17px;
      font-weight: 800;
      font-family: SimHei;
      padding: 0px 0 7px 0;
      //position: sticky;
      //top: 0;
    }
  }

  .banner {
    padding-top: 50px;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bottom {
  }
}
.yidong {
  width: 100%;
  //position: sticky;
  //top: 0;
  z-index: 1000;
}
.inputS {
  border: 0;
  caret-color: transparent;
}
.selectTg {
  width: 100%;
  height: 4px;
  background-color: #0191FB;
  margin-top: 3px;
}
.top-tag-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.list_tag {
  background-color: white;
  border-radius: 15px;
  padding: 15px;
  margin: 10px 15px 0 15px;
  box-shadow: 0 0 5px #eaeaea; /* 水平偏移 垂直偏移 模糊半径 阴影颜色 */
  .list_tag_title_JX {
    float: left;
    display: inline-block;
    margin: 2.5px 7px 0 0;
    width: 34px;
    height: 17px;
    font-family: SimHei;
    font-weight: 800;
    line-height: 19px;
    text-align: center;
    color: white;
    border-radius: 3px;
    background: linear-gradient(to left, #50E094, #16ba63);
    box-shadow: 0 0 5px 2px rgba(22, 186, 99, .3);
    font-size: 10px;
  }
  .list_tag_title_ZP {
    float: left;
    display: inline-block;
    margin: 2.5px 7px 0 0;
    width: 34px;
    height: 17px;
    font-family: SimHei;
    font-weight: 800;
    line-height: 17px;
    text-align: center;
    color: white;
    border-radius: 3px;
    box-shadow: 0 0 5px 2px rgba(253, 151, 179, .3);
    background: linear-gradient(to left, #FFC3B7, #fd97b0);
    font-size: 10px;
  }
  .list_tag_title_PX {
    float: left;
    display: inline-block;
    margin: 2.5px 7px 0 0;
    width: 34px;
    height: 17px;
    font-family: SimHei;
    font-weight: 800;
    line-height: 19px;
    text-align: center;
    color: white;
    border-radius: 3px;
    box-shadow: 0 0 5px 2px rgba(239, 187, 106, .3);
    background: linear-gradient(to left, #FFD595, #efbb6a);
    font-size: 10px;
  }

  .list_tag_title_JI {
    display: inline-block;
    margin-left: 5px;
    //padding: 0 3px;
    //margin-top: -5px;
    color: white;
    width: 20px;
    text-align: center;
    //height: 20px;
    font-size: 13px;
    border-radius: 6px;
  }

  .list_tag_tag1 {
    margin-right: 10px;
    padding: 3px 7px;
    color: #666666;
    font-size: 12px;
    background-color: #F2F2F2;
    margin-top: 7px;
    border-radius: 2px;
  }
}
.list_tag_time {
  color: #999999;
  font-size: 10px;
  padding-top: 5px;
}
.top_change {
  background-color: white;
  display: flex;
  padding: 0 10px 10px 20px;
  flex-wrap: wrap;
}
.van-overlay {
  z-index: 1001;
}
.box2 {
  height: 17px;
  padding: 5px 10px;
  position: relative;
  text-align: center;
  margin-right: 10px;
  margin-top: 5px;
  font-size: 13px;
}
</style>