<template>
  <div class="box2" :style="{backgroundColor: !selectValue ? '#F2F2F2' : '#DBEFFF',color: !selectValue ? 'black' : '#0991FA'}">
    <div style="width: 100%;height: 100%" @click="open">
      <img :src="!selectValue ? selectImg : noSelect" style="width: 10px;position: absolute;bottom: 3px;right: 3px">
      {{selectValue ? selectValue : defaultValue}}
    </div>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import selectImg from '@/assets/image/Recruitment/hsS.png'
import noSelect from '@/assets/image/Recruitment/lsS.png'

export default {
  name: "ChangeTab",
  props: {
    columns: {
      type: Array,
      required: true
    },
    defaultValue: {
      type: String,
      required: true
    },
    apiColumns: {
      type: String,
      required: true
    },
    noSelectValue: {
      type: String,
      required: true
    }
  },
  watch: {
    noSelectValue : {
      immediate: false,
      handler(to) {
        if (!to || to === '') {
          this.selectValue = ''
        }
      }
    }
  },
  data () {
    return {
      selectImg,
      noSelect,
      showPicker: false,
      selectValue: '',
    }//dataReturn
  },
  methods: {
    onConfirm (e) {
      this.selectValue = e
      this.showPicker = false
      this.$emit('change',this.selectValue, this.apiColumns)
    },
    open () {
      this.showPicker = true
    }
  },
}
</script>

<style lang="scss" scoped>
.box2 {
  height: 17px;
  padding: 5px 10px;
  position: relative;
  text-align: center;
  margin-right: 10px;
  margin-top: 5px;
  font-size: 13px;
}
</style>